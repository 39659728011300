'use client';

import { useCallback, useLayoutEffect, useState } from 'react';

import { bannerCookieName } from '@/blocks/components/DownloadAppBanner/utils';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useDeviceInfo } from '@/hooks/useDeviceDetect';
import { getCookie, setCookie } from '@/utils/cookies';

export function useDownloadAppBanner() {
  const [downloadAppBannerVisible, setDownloadAppBannerVisible] = useState(false);
  const bp = useBreakpoint();
  const deviceInfo = useDeviceInfo();

  useLayoutEffect(() => {
    setDownloadAppBannerVisible(!Boolean(getCookie(bannerCookieName)));
  }, []);

  const handleClose = useCallback(() => {
    setDownloadAppBannerVisible(false);
    setCookie(
      bannerCookieName,
      JSON.stringify({
        createdAt: Date.now(),
        value: {
          close: 1,
        },
      }),
    );
  }, [setDownloadAppBannerVisible]);

  return {
    isVisible: downloadAppBannerVisible && bp !== 'desktop' && deviceInfo?.isMobile,
    close: handleClose,
  };
}

import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout-shared.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["WithQueryParamStorage"] */ "/opt/atlassian/pipelines/agent/build/src/app/[lang]/(partners)/WithQueryParam/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DownloadAppBannerRenderer"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/DownloadAppBanner/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NBSP","BR"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/I18nSubstitutions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithIntercom"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Intercom/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomCustomLauncher"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/components/Intercom/Launchers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/mui/ThemeRegistry/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Warnings","WarningsPartners"] */ "/opt/atlassian/pipelines/agent/build/src/blocks/sections/Warnings/Warnings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitXDClient"] */ "/opt/atlassian/pipelines/agent/build/src/global/xd-storage/init-xd-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/opt/atlassian/pipelines/agent/build/src/providers/AB/provider/FeatureFlagsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtmLoadedProvider"] */ "/opt/atlassian/pipelines/agent/build/src/providers/analytics/gtmLoadedProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithAnalytics"] */ "/opt/atlassian/pipelines/agent/build/src/providers/analytics/WithAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlockViewportPositionProvider"] */ "/opt/atlassian/pipelines/agent/build/src/providers/BlockViewportPositionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BuyCryptoWidgetProvider"] */ "/opt/atlassian/pipelines/agent/build/src/providers/BuyCryptoWidgetProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageOverlayElementsStack"] */ "/opt/atlassian/pipelines/agent/build/src/providers/PageOverlayElementsStack/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithAPKModal"] */ "/opt/atlassian/pipelines/agent/build/src/providers/WithAPKModal/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/global.scss");

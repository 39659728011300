'use client';

import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as amplitude from '@amplitude/analytics-browser';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useLocale } from 'next-intl';

import { createContextHook } from '@/hooks/createContextHook';
import { useLastUtmSourceParams } from '@/hooks/useLastUtmSource';
import { usePathname } from '@/i18n/routing';
import { pageViewTrackingEnrichment } from '@/providers/analytics/plugins';
import { ctaEventProps } from '@/providers/analytics/types';
import { getClosestEventValue, getClosestLink, log } from '@/providers/analytics/utils';
import { snakeCase } from '@/utils/string';

const instance = amplitude.createInstance();
// const sessionReplayTracking = sessionReplayPlugin();

export type AnalyticsContextState = {
  track: (data: Record<string, any>) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextState | undefined>(undefined);

const paramMapping = {
  utmcsr: 'LSND_source',
  utmcmd: 'LSND_medium',
  utmccn: 'LSND_campaign',
  utmcct: 'LSND_content',
  utmctr: 'LSND_term',
  pid: 'LSND_pid',
  signup: 'LSND_signup',
  ga_sid: 'LSND_gasid',
};

const UTM_PARAMS_WAIT_MAX_TIME_MS = process.env.NODE_ENV === 'development' ? 10000 : 5000;
const UTM_PARAMS_WAIT_RETRY_INTERVAL = 500;
const UTM_PARAMS_WAIT_MAX_RETRIES = UTM_PARAMS_WAIT_MAX_TIME_MS / UTM_PARAMS_WAIT_RETRY_INTERVAL;

export const WithAnalytics = ({
  children,
  amplitudeId,
}: PropsWithChildren<{
  amplitudeId?: string;
}>) => {
  const language = useLocale();
  const pathname = usePathname();
  const isInitialized = useRef<boolean>(false);

  const { lastUtmSourceParams } = useLastUtmSourceParams(paramMapping);
  const utmParamsRetries = useRef<number>(0);

  const analyticsContextValue: AnalyticsContextState = useMemo(
    () => ({
      track: (properties) => {
        /**
         * https://trade-team.atlassian.net/wiki/spaces/Prime/pages/3932553219/African+Website
         */
        const { origin, hostname, pathname: pathnameWithPrefix, href } = window.location;

        const eventProperties = {
          event_subcategory: pathname === '/' ? 'main' : pathname,
          // event_action: 'button_click',
          // event_name: 'site_cta_buttons_click',
          // event_category: 'site_cta_buttons',
          ...ctaEventProps,
          language,
          ...Object.fromEntries(
            Object.entries(properties)
              .filter(([k, v]) => typeof v !== 'undefined')
              .map(([k, v]) => [k, k === 'click_url' ? v : snakeCase(v)]),
          ),
          '[Amplitude] Page Domain': hostname, // subdomain or domain without http (example: primexbt.com)
          '[Amplitude] Page Path': pathnameWithPrefix, // page path without http and domain with page path and without query params (example /id/sign-up)
          '[Amplitude] Page URL': origin + pathnameWithPrefix, // full URL without query params (example: https://primexbt.com/id/sign-up)
          '[Amplitude] Page Location': href, // full URL with query params (example: https://primexbt.com/id/sign-up?ref=12345)
        };
        const eventName =
          eventProperties.event_category === 'site_menu' || eventProperties.event_category === 'site_sub_menu'
            ? 'site_menu_click'
            : eventProperties.event_category === 'site_cta_buttons'
              ? 'site_cta_buttons_click'
              : eventProperties.event_name;

        log(eventName, eventProperties);
        instance.track(eventName, eventProperties);
      },
    }),
    [language, pathname],
  );

  const onAnyPageElementClick = useCallback(
    function onClick(e: MouseEvent) {
      const closestLink = getClosestLink(e.target);

      if (!closestLink) {
        return;
      }

      const event_label = snakeCase(getClosestEventValue(closestLink, 'label'));
      const event_action = getClosestEventValue(closestLink, 'action');
      const event_subcategory = getClosestEventValue(closestLink, 'subcategory');
      const event_name = getClosestEventValue(closestLink, 'name');
      const event_category = getClosestEventValue(closestLink, 'category');
      const block_name = getClosestEventValue(closestLink, 'block-name');
      const location = getClosestEventValue(closestLink, 'location');
      const include_url = getClosestEventValue(closestLink, 'include-url');
      const href = closestLink.href;

      analyticsContextValue.track({
        event_name,
        event_subcategory,
        event_category,
        event_label,
        event_action,
        block_name,
        location,
        click_url: Boolean(include_url) ? href : undefined,
      });
    },
    [analyticsContextValue],
  );

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    if (!amplitudeId) {
      return console.error('No amplitudeId provided', amplitudeId);
    }

    function initAmplitude() {
      if (isInitialized.current || !amplitudeId) {
        return false;
      }
      instance.add(pageViewTrackingEnrichment({ utmParams: lastUtmSourceParams || {} }));
      // instance.add(sessionReplayTracking);
      instance.init(amplitudeId);
      isInitialized.current = true;
    }

    const interval = setInterval(() => {
      if (lastUtmSourceParams || utmParamsRetries.current > UTM_PARAMS_WAIT_MAX_RETRIES) {
        if (utmParamsRetries.current > UTM_PARAMS_WAIT_MAX_RETRIES) {
          console.error('Launch amplitude, Couldnt wait for last_utm_source, max reties reached');
        } else {
          console.info('Launch amplitude');
        }
        initAmplitude();
        clearInterval(interval);
        return;
      }
      utmParamsRetries.current = utmParamsRetries.current + 1;
    }, UTM_PARAMS_WAIT_RETRY_INTERVAL);

    return () => clearInterval(interval);
  }, [amplitudeId, lastUtmSourceParams]);

  useEffect(() => {
    document.addEventListener('click', onAnyPageElementClick);
    return () => document.removeEventListener('click', onAnyPageElementClick);
  }, [onAnyPageElementClick]);

  return <AnalyticsContext.Provider value={analyticsContextValue}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = createContextHook(AnalyticsContext, 'AnalyticsContext');

import { getCookie } from '@/utils/cookies';

export const BANNER_DESKTOP_HEIGHT = '0px'; // 60px
export const BANNER_MOBILE_HEIGHT = '52px';
export const bannerCookieName = 'app-banner-closed';
const checkBannerVisibility = (): boolean => {
  return !getCookie(bannerCookieName);
};
const getBannerHeight = (
  isVisible: boolean,
  isDesktop: boolean | null,
  isIOS: boolean,
  isAndroid: boolean,
) => {
  if (!isVisible) return '0px';
  if (isDesktop) return BANNER_DESKTOP_HEIGHT;
  if (isIOS || isAndroid) return BANNER_MOBILE_HEIGHT;
  return '0px';
};

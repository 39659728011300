'use client';

import { ReactNode, useLayoutEffect, useState } from 'react';

import { IResult, UAParser } from 'my-ua-parser';

import { nodeEnv } from '@/global/public-settings';
import { DeviceType, OS } from '@/hooks/useDeviceDetect/enums';

let deviceInfo: IResult;

type UseDeviceInfoReturn = Partial<
  IResult & {
    isAndroid: boolean;
    isIOS: boolean;
    isMobile: boolean;
  }
> | null;

export const useDeviceInfo = (): UseDeviceInfoReturn => {
  const [deviceInfoState, setDeviceInfoState] = useState<UseDeviceInfoReturn>(null);

  useLayoutEffect(() => {
    if (!deviceInfoState) {
      deviceInfo ||= new UAParser(window.navigator.userAgent).getResult();
      setDeviceInfoState({
        ...deviceInfo,
        isAndroid: deviceInfo.os.name === OS.ANDROID,
        isIOS: deviceInfo.os.name === OS.IOS,
        // ...(nodeEnv === 'development' && {
        //   isAndroid: deviceInfo.os.name === OS.IOS,
        //   isIOS: deviceInfo.os.name === OS.ANDROID,
        //   os: {
        //     ...deviceInfo.os,
        //     name: 'Android',
        //   },
        // }),
        isMobile: ([DeviceType.MOBILE, DeviceType.TABLET] as any[]).includes(deviceInfo?.device?.type),
      });
    }
  }, [deviceInfoState]);

  return deviceInfoState;
};

export function DeviceMatch({
  devices,
  fallback = null,
  loading = null,
}: {
  devices: Record<OS, ReactNode>;
  loading?: ReactNode;
  fallback?: ReactNode;
}) {
  const deviceInfo = useDeviceInfo();
  if (!deviceInfo) {
    return loading;
  }
  return devices[deviceInfo?.os?.name as OS] || fallback;
}

'use client';

import dynamic from 'next/dynamic';

import { useDownloadAppBanner } from '@/blocks/components/DownloadAppBanner/useDownloadAppBanner';

const DownloadAppBanner = dynamic(() =>
  import('./DownloadAppBannerBottom').then((m) => m.DownloadAppBannerBottom),
);

export const DownloadAppBannerRenderer = () => {
  const { isVisible, close } = useDownloadAppBanner();

  if (!isVisible) {
    return null;
  }

  return <DownloadAppBanner close={close} />;
};

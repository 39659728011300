export enum OS {
  ANDROID = 'Android',
  IOS = 'iOS',
}

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}
